body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(circle at top, rgb(0, 130, 230), rgb(0, 175, 190));
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --size: 10px;
}

.main-form .modal-content {
  border-radius: 40px;
}

.modal-border .modal-content {
  border: solid var(--size) transparent;
  border-radius: 40px;
}

/* Gradient border rendering for results modal */
.modal-border .modal-content::after {
  position: absolute;
  top: calc(-1* var(--size)); bottom: calc(-1* var(--size));
  left: calc(-1* var(--size)); right: calc(-1* var(--size));
  background: linear-gradient(to left, rgb(0, 130, 230), rgb(0, 175, 190));
  content: '';
  z-index: -1;
  border-radius: 40px;
}